<template>
    <div class="quillEditor">
        <quill-editor v-model="msg" :options="editorOption" :disabled="disable"></quill-editor>
    </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
/*富文本编辑图片上传配置*/
const uploadConfig = {
    action:  '/driver-admin/info/announcement/upload',  // 必填参数 图片上传地址
    methods: 'POST',  // 必填参数 图片上传方式
    token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
    name: 'file',  // 必填参数 文件的参数名
    size: 2048,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
    accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'  // 可选 可上传的图片格式
};
const handlers = {
    image: function image() {
        var self = this;

        var fileInput = this.container.querySelector('input.ql-image[type=file]');
        if (fileInput === null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            // 设置图片参数名
            if (uploadConfig.name) {
                fileInput.setAttribute('name', uploadConfig.name);
            }
            // 可设置上传图片的格式
            fileInput.setAttribute('accept', uploadConfig.accept);
            fileInput.classList.add('ql-image');
            // 监听选择文件
            fileInput.addEventListener('change', function () {
                // 创建formData
                var formData = new FormData();
                formData.append(uploadConfig.name, fileInput.files[0]);
                // 图片上传
                var xhr = new XMLHttpRequest();
                xhr.open(uploadConfig.methods, uploadConfig.action, true);
                //设置请求头token
                xhr.setRequestHeader("token", uploadConfig.token);
                // 上传数据成功，会触发
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        var res = JSON.parse(xhr.responseText);
                        console.log(res)
                        let length = self.quill.getSelection(true).index;
                        //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。            
                        self.quill.insertEmbed(length, 'image', res.detail);
                        self.quill.setSelection(length + 1)
                    }
                    fileInput.value = ''
                };
                // 开始上传数据
                xhr.upload.onloadstart = function () {
                    fileInput.value = ''
                };
                // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
                xhr.upload.onerror = function () {
                };
                // 上传数据完成（成功或者失败）时会触发
                xhr.upload.onloadend = function () {
                    // console.log('上传结束')
                };
                xhr.send(formData)
            });
            this.container.appendChild(fileInput);
        }
        fileInput.click();
    }
};
// 富文本工具栏配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],      // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{'align': []}],
    // ['link', 'image', 'video'],
    ['image'],
    ['clean']                                         // remove formatting button
];
export default {
    props: {
        content: {
            type: String,
            default: ''
        },
        disable:{
            type: Boolean,
            default: false,
        },
    },
    components: {
        quillEditor
    },
    methods: {
        reset() {
            this.msg = this.content
        }
    },
    watch: {
        'content': {
            handler(newValue) {
                this.msg = newValue
            }
        },
        'msg': {
            handler(newValue) {
                this.$emit('change', newValue)
            }
        }
    },
    data() {
        return {
            msg: this.content,
            editorOption: {
                placeholder: '请输入内容',
                theme: 'snow',  // or 'bubble'
                modules: {
                    // imageResize: { //调整大小组件。
                    //     displayStyles: {
                    //         backgroundColor: 'black',
                    //         border: 'none',
                    //         color: 'white'
                    //     },
                    //     modules: ['Resize', 'DisplaySize', 'Toolbar']
                    // },
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: handlers,
                    }
                }
            },
        };
    }
};
</script>
<style lang="scss" scoped="quillEditor">
</style>
<style lang="scss">
.quillEditor{
    .quill-editor{
        .ql-container{
            height: 200px;
        }
    }
}
</style>

