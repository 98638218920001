<template>
    <jy-dialog :visible.sync="visible" :title="title" custom-class="dialog_form" @close="handleClose">
        <div>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="咨讯分类" prop="imT">
                            <el-select v-model="form.imT">
                                <el-option v-for="i in $parent.infoTypeList" :key="i.iValue" :label="i.iValue"
                                    :value="i.iValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属固废类别" prop="wasteTId">
                            <el-select v-model="form.wasteTId">
                                <el-option v-for="t in $parent.wasteTypeList" :key="t.wasteTId" :label="t.cName"
                                    :value="t.wasteTId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="咨讯标题" prop="title">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="咨讯内容" prop="content">
                        <rich-text :content="form.content" @change="handleChange" ref="richText"></rich-text>
                    </el-form-item>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="置顶" prop="isTop">
                            <el-select v-model="form.isTop">
                                <el-option v-for="i in $parent.isTopOptions" :key="i.value" :label="i.text"
                                    :value="i.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="发布状态" prop="status">
                            <el-select v-model="form.status">
                                <el-option v-for="s in $parent.statusOptions" :key="s.value" :label="s.text"
                                    :value="s.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20" v-if="form.status === '2'">
                    <el-col :span="12">
                        <el-form-item label="发布时间" prop="puTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="puTime">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="submit">发布</el-button>
        </div>
    </jy-dialog>
</template>

<script>
import RichText from '@/components/common/quillEditor.vue'
export default {
    components: {
        RichText
    },
    created() {
    },
    data() {
        return {
            visible: false,
            title: "新增",
            puTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),

            form: {
                isTop: '0',
                status: '1',
                wasteTId: '',
                imT: '',
                content: '',
                cUserNa: ''
            },
            formBak: {},
            rules: {
                imT: [
                    {
                        required: true,
                        message: "请选择咨讯分类",
                        trigger: "change"
                    }
                ],
                wasteTId: [
                    {
                        required: true,
                        message: "请选择固废类别",
                        trigger: "change"
                    }
                ],
                title: [
                    {
                        required: true,
                        message: "请输入咨讯标题",
                        trigger: "change"
                    }
                ],
                content: [
                    {
                        required: true,
                        message: "请输入咨讯内容",
                        trigger: "change"
                    }
                ]
            },
        };
    },
    methods: {
        init() {
            this.visible = true;
            this.formBak = JSON.parse(JSON.stringify(this.form))
            if (this.$refs.richText) {
                this.$refs.richText.reset()
            }
        },
        reset() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },
        handleChange(newValue) {
            this.form.content = newValue
        },
        handleClose() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const cUserNa = this.$store.state.info.userNa;
                    this.form.cUserNa = cUserNa
                    if (this.form.status === '2') {
                        this.form.puTime = this.puTime
                    }
                    this.$http.post('/information/add', this.form)
                        .then(() => {
                            this.visible = false;
                            this.$emit('update')
                        })
                    console.log(this.form)
                }

            })
        }
    }
};
</script>
<style>
</style>