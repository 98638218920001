<template>
    <jy-dialog :visible.sync="visible" :title="title" custom-class="dialog_form">
        <div>
            <el-form :model="form" :rules="rules" ref="form" :disabled="!editable" label-width="120px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="咨讯分类" prop="imT">
                            <el-select v-model="form.imT">
                                <el-option v-for="i in $parent.infoTypeList" :key="i.iValue" :label="i.iValue"
                                    :value="i.iValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属固废类别" prop="wasteTId">
                            <el-select v-model="form.wasteTId">
                                <el-option v-for="t in $parent.wasteTypeList" :key="t.wasteTId" :label="t.cName"
                                    :value="t.wasteTId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="咨讯标题" prop="title">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="咨讯内容" prop="content">
                        <rich-text :content="form.content" :disable="!editable" ref="richText" @change="handleChange"></rich-text>
                        <!-- <el-input v-model="form.content"></el-input> -->
                    </el-form-item>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="置顶" prop="isTop">
                            <el-select v-model="form.isTop">
                                <el-option v-for="i in $parent.isTopOptions" :key="i.value" :label="i.text"
                                    :value="i.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发布状态" prop="status">
                            <el-select v-model="form.status">
                                <el-option v-for="s in $parent.statusOptions" :key="s.value" :label="s.text"
                                    :value="s.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="发布时间" prop="puTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.puTime">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="创建人" prop="cUserNa">
                            <el-input v-model="form.cUserNa"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="创建时间" prop="createT">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.createT">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </div>
        <div slot="footer" class="dialog-footer" v-if="editable">
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </div>
    </jy-dialog>
</template>

<script>
import RichText from '@/components/common/quillEditor.vue'
export default {
    components: {
        RichText
    },
    created() {
    },
    data() {
        return {
            visible: false,
            editable: false,
            title: "",

            form: {},
            formBak: {},
            rules: {
                imT: [
                    {
                        required: false,
                        message: "请选择咨讯分类",
                        trigger: "change"
                    }
                ],
                wasteTId: [
                    {
                        required: false,
                        message: "请选择固废类别",
                        trigger: "change"
                    }
                ],
                title: [
                    {
                        required: true,
                        message: "请输入咨讯标题",
                        trigger: "change"
                    }
                ],
                content: [
                    {
                        required: true,
                        message: "请输入咨讯内容",
                        trigger: "change"
                    }
                ],
                isTop: [
                    {
                        required: false,
                        message: "请选择是否置顶",
                        trigger: "change"
                    }
                ],
                status: [
                    {
                        required: false,
                        message: "请选择状态",
                        trigger: "change"
                    }
                ],
                cUserNa: [
                    {
                        required: true,
                        message: "请输入创建人",
                        trigger: "change"
                    }
                ],
                puTime: [
                    {
                        required: true,
                        message: "请选择发布时间",
                        trigger: "change"
                    }
                ],
                createT: [
                    {
                        required: true,
                        message: "请选择创建时间",
                        trigger: "change"
                    }
                ]
            }
        };
    },
    methods: {
        init(options, editable) {
            this.form = JSON.parse(JSON.stringify(options))
            this.formBak = JSON.parse(JSON.stringify(options))
            this.visible = true;
            this.editable = editable;
            if (this.editable) {
                this.title = "编辑";
            } else {
                this.title = "查看";
            }
            if (this.$refs.richText) {
                this.$refs.richText.reset()
            }
        },
        reset() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },
        handleChange(newValue) {
            this.form.content = newValue
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$http.post('/information/update', this.form)
                        .then(() => {
                            this.visible = false
                            this.$emit('update')
                        })
                }
            })
        }
    }
};
</script>
<style>
</style>