<template>
    <div>
        <jy-query ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="标题">
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </jy-query-item>
            <jy-query-item label="咨讯分类">
                <el-select v-model="form.imT">
                    <el-option v-for="i in infoTypeList" :key="i.iValue" :label="i.iValue" :value="i.iValue">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypeList" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="置顶">
                <el-select v-model="form.isTop">
                    <el-option v-for="i in isTopOptions" :key="i.value" :label="i.text" :value="i.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="发布状态">
                <el-select v-model="form.status">
                    <el-option v-for="s in statusOptions" :key="s.value" :label="s.text" :value="s.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="发布时间" :span="1.5">
                <el-date-picker v-model="puTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item label="创建时间" :span="1.5">
                <el-date-picker v-model="createT" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="query"
                    v-if="btnexist('wastesSystemSettingsInformationList')">查询</el-button>
                <el-button size="small" type="primary" @click="reset"
                    v-if="btnexist('wastesSystemSettingsInformationReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-if="btnexist('wastesSystemSettingsInformationInsert')" @click="handleAdd">新增
                    </el-button>
                    <el-button size="small" type="primary" v-if="btnexist('wastesSystemSettingsInformationBatchDelete')"
                        @click="handleBatchDelete">批量删除</el-button>
                </el-row>
            </template>
        </jy-query>

        <div class="jy-table" v-loading="loading">
            <jy-table :data="list" @selection-change="handleSelectionChange">
                <jy-table-column type="selection" width="55"></jy-table-column>
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column label="标题" prop="title"></jy-table-column>
                <jy-table-column label="咨讯分类" prop="imT"></jy-table-column>
                <jy-table-column label="所属固废类别">
                    <template v-slot="scope">
                        {{ getWasteType(scope.row.wasteTId) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="置顶">
                    <template v-slot="scope">
                        {{ getIsTop(scope.row.isTop) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="发布状态">
                    <template v-slot="scope">
                        {{ getStatus(scope.row.status) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="发布时间" prop="puTime"></jy-table-column>
                <jy-table-column label="浏览数" prop="rNum"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT"></jy-table-column>
                <jy-operate :list="operateList" width="200"></jy-operate>
            </jy-table>
        </div>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
            :page-size="pageSize" :total="total"></jy-pagination>
        <view-or-update ref="dialog" @update="query"></view-or-update>
        <add-info ref="addDialog" @update="query"></add-info>
    </div>
</template>

<script>
import { btnMixins } from "@/common/js/button.mixin";
import viewOrUpdate from "@/components/pages/admin/systemSettings/informationManagement/viewOrUpdate.vue";
import addInfo from "@/components/pages/admin/systemSettings/informationManagement/add.vue";

export default {
    mixins: [btnMixins],
    components: {
        viewOrUpdate,
        addInfo
    },
    data() {
        return {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            infoTypeList: [],
            btnMenuId: "wastesSystemSettingsInformation",
            isTopOptions: [
                {
                    text: '全部',
                    value: ''
                },
                {
                    text: '否',
                    value: '0'
                },
                {
                    text: '是',
                    value: '1'
                },
            ],
            statusOptions: [
                {
                    text: '全部',
                    value: ''
                },
                {
                    text: '禁用',
                    value: '0'
                },
                {
                    text: '发布',
                    value: '1'
                },
                {
                    text: '计划发布',
                    value: '2'
                }
            ],
            wasteTypeList: [],
            operateList: [],
            puTime: [],
            createT: [],

            form: {
                imT: '',
                wasteTId: '',
                title: '',
                isTop: '',
                status: '',
                // createTStart: '',
                // createTEnd: '',
                // puTimeStart: '',
                // puTimeEnd: ''
            },
            list: [],
            selection: []
        }
    },
    created() {
        this.setOperateList()
        //this.initTime()
        Promise.all([
            this.getInfoTypeList(),
            this.getWasteTypeList()
        ])
        .then(() => {
            this.query()
        })
    },
    activated(){
        this.query()
    },
    watch: {
        'createT': {
            handler(newValue) {
                this.form.createTStart = newValue[0]
                this.form.createTEnd = newValue[1]
            },
            deep: true,
        },
        'puTime': {
            handler(newValue) {
                this.form.puTimeStart = newValue[0]
                this.form.puTimeEnd = newValue[1]
            },
            deep: true,
        }
    },
    methods: {
        query() {
            this.loading = true
            this.$http.post('/information/pageList', {
                ...this.form
            })
                .then(res => {
                    this.total = res.detail.total
                    this.list = res.detail.list
                    console.log(res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        reset() {
            Object.keys(this.form).forEach(key => {
                this.form[key] = '';
            })
            //this.initTime()
            this.form.wasteTId = this.wasteTypeList[0].wasteTId
            this.query()
        },
        initTime() {
            this.puTime = this.$util.formatInitTime(6, 0, true)
            this.createT = this.$util.formatInitTime(6, 0, true)
        },
        async getInfoTypeList() {
            return this.$http.post('/dict/getDictValuesByDictId', 'dictinformation')
                .then(res => {
                    this.infoTypeList = res.detail
                    console.log(res)
                })
        },
        async getWasteTypeList() {
            return this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypeList = res.detail;
                    this.form.wasteTId = res.detail[0].wasteTId;
                });
        },
        getWasteType(wasteTId) {
            return this.wasteTypeList.find(each => each.wasteTId === wasteTId).cName
        },
        getStatus(value) {
            return this.statusOptions.find(each => each.value === value).text
        },
        getIsTop(value) {
            return this.isTopOptions.find(each => each.value === value).text
        },
        setOperateList() {
            this.operateList = [
                {
                    name: "查看",
                    icon: "el-icon-edit-outline",
                    fun: this.showDetail,
                    isShow: () => {
                        return this.btnexist("wastesSystemSettingsInformationView");
                    }
                },
                {
                    name: "编辑",
                    icon: "el-icon-edit-outline",
                    fun: this.handleEdit,
                    isShow: () => {
                        return this.btnexist("wastesSystemSettingsInformationUpdate");
                    }
                },
                {
                    name: "删除",
                    icon: "el-icon-edit-outline",
                    needConfirm: true,
                    title: '确定删除吗?',
                    fun: this.handleDelete,
                    isShow: () => {
                        return this.btnexist("wastesSystemSettingsInformationDelete");
                    }
                },
                {
                    name: "禁用",
                    icon: "el-icon-edit-outline",
                    needConfirm: true,
                    title: '确定禁用吗?',
                    fun: this.handleDisable,
                    isShow: (row) => {
                        return this.btnexist("wastesSystemSettingsInformationDisable") && row.status !== '0';
                    }
                },
                {
                    name: "启用",
                    icon: "el-icon-edit-outline",
                    fun: this.handleEnable,
                    isShow: (row) => {
                        return this.btnexist("wastesSystemSettingsInformationEnable") && row.status === '0';
                    }
                }
            ]
        },
        handleDisable(row) {
            this.$http.post('/information/banned', row.imId)
                .then(() => {
                    this.query()
                })
        },
        handleEnable(row) {
            this.$http.post('/information/enable', row.imId)
                .then(() => {
                    this.query()
                })
        },
        handleDelete(row) {
            this.$http.post('/information/delete', row.imId)
                .then(() => {
                    this.query()
                })
        },
        handleAdd() {
            this.$refs.addDialog.init()
        },
        handleBatchDelete() {
            if (this.selection.length) {
                this.$confirm('确定要批量删除吗')
                    .then(() => {
                        const imIds = this.selection.map(each => each.imId)
                        this.$http.post('/information/batchDeleteByImIds', { imIds })
                            .then(() => {
                                this.query()
                            })
                    })
                    .catch(() => { })
            }
        },
        handleSelectionChange(val) {
            this.selection = val
        },
        handleSizeChange(val) {
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
        },
        showDetail(row) {
            this.$refs.dialog.init(row)
        },
        handleEdit(row) {
            this.$refs.dialog.init(row, true)
        }
    }
}
</script>